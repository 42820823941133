import React, { useContext, useEffect, useState } from "react";
import CircleButton from "../../components/CircleButton/CircleButton";
import "./TopSection.scss";
import KushalImage from "./../../assets/images/kushal.jpeg";
import KushalAlternativeImage from "./../../assets/images/kushalbg.jpeg";
import { CommonConfig, Icons } from "../../config";
import { ThemeContext } from "../../context/ThemeContext";

const TopSection = () => {
  const { theme } = useContext(ThemeContext);
  const [profileImage, setProfileImage] = useState(KushalImage);
  const [currentTaglineIndex, setCurrentTaglineIndex] = useState(0);

  useEffect(() => {
    setProfileImage(theme === 'dark' ? KushalImage : KushalImage);
  }, [theme]);

  useEffect(() => {
    const taglineInterval = setInterval(() => {
      setCurrentTaglineIndex((prevIndex) => (prevIndex + 1) % CommonConfig.taglines.length);
    }, 3000); // Change tagline every 3 seconds

    return () => clearInterval(taglineInterval); // Cleanup interval on component unmount
  }, []);


  return (
    <>
      <div className="top-section">
        <div className="intro">
          <h1>{CommonConfig.name}</h1>
          <p>{CommonConfig.taglines[currentTaglineIndex]}</p>
        </div>
        <div className="profile-image">
          <img
            src={profileImage}
            alt="ProfileImage"
            style={{ maxWidth: "50%", height: "auto" }}
          />
        </div>
      </div>
      <div className="social">
        {CommonConfig.social.map((socialDetails, index) => {
          return (
            <CircleButton
              key={"top-section-social-" + index}
              tooltip={socialDetails.name}
              tooltipPlacement="top"
              link={socialDetails.link}
              target="_blank"
            >
              {socialDetails.icon
                ? socialDetails.icon
                : Icons[socialDetails.name.toLowerCase()]}
            </CircleButton>
          );
        })}
      </div>
    </>
  );
};

export default TopSection;
