/**
 * This is the config file where you can edit information as you want.
 *
 * There are 3 variable objects which contain all the information.
 *
 * 1. CommonConfig
 *      This variable contains all the common configuration of your profile
 *      including your name, your social links and the path to draw the signature.
 * 2. ProjectsConfig
 *      This variable contains all the information which will be part of the
 *      projects section. Here you can add your own projects and provide a link for them
 *      and also change the icon as per your liking.
 * 3. CustomSectionsConfig
 *      Now this is the fun part. This variable is an array which can hold any number
 *      of sections. Here you can define any of your custom sections may it be "Work", "Reviews",
 *      "Portfolio" or whatnot. The "content" property supports JSX so you can style or use it
 *      however you like!
 *
 * So what are you waiting for? GO FOR IT!
 */

import React from "react";

import { Icons } from "./icons";

// CUSTOM ICONS
import SettingsInputAntennaIcon from "@material-ui/icons/SettingsInputAntenna";
import WbIridescentIcon from "@material-ui/icons/WbIridescent";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import ComputerIcon from "@material-ui/icons/Computer";
import InfoIcon from "@material-ui/icons/Info";
import BookIcon from "@material-ui/icons/Book";
import SkillIcon from "@material-ui/icons/SportsSoccerOutlined";
import AddIcCallIcon from '@material-ui/icons//AddIcCall';
import RateReviewIcon from "@material-ui/icons/RateReview";
import Description from "@material-ui/icons/Description";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import PublicIcon from "@material-ui/icons/Public";

// CUSTOM COMPONENTS
import CircleButton from "../components/CircleButton/CircleButton";
import ContactForm from "../sections/ContactForm/ContactForm";

// CONFIGURABLE TEXT BEGINS HERE

/** Common config for top section and footer */
const CommonConfig = {
  // Name that will be displayed at the top
  name: "Hello I'm Kushal Shrestha",
  // Tagline that will be displayed after the name
  taglines: [
    "Software Engineer Based in Oregon, USA",
    "Software Engineer From Nepal",
    "Loves Data and Soccer",
    "Passionate About Work and Eager to Learn/Teach",
  ],
  image: {
    imageUrl: `src/assets/images/kushal.jpeg`,
  },
  // Configuration for signature in the top section

  email: "kushalshr@gmail.com",
  // Information for social media accounts
  social: [
    {
      // Name of the social media platform
      name: "GitHub",
      // Link to your account
      link: "https://github.com/kushalshrestha",
      // (Optional) Icon of the social media platform
      // default icon will be used in case of no value
      icon: Icons["github"],
    },
    {
      name: "LinkedIn",
      link: "https://linkedin.com/in/kushal-shrestha/",
    },
    {
      name: "Email",
      link: "mailto:kushalshr@gmail.com",
    },
    {
      name: "CV",
      link: "https://docs.google.com/document/d/1gGsbqQK5on01rxiyhF7fNHwFg8QbMXqPTm0-91uZvps/edit?usp=sharing",
      icon: <Description />,
    },
  ],
};

/** Config for the projects section */
const ProjectsConfig = {
  // Name / title of the section
  name: "projects",
  // Icon next to the header title. The headerIcon can use any SVG icon, material icons are preferred
  // See https://material-ui.com/components/material-icons/
  headerIcon: <ComputerIcon />,
  // Extra classes to apply styling
  // "section-reverse" class makes a section flow from right to left
  extraClass: "",
  // Projects
  projects: [
    {
      // Name of the project
      name: "Bookmundi - better way of traveling and touring the world!",
      // Icon of the project
      icon: Icons["world"],
      // Description of the project
      description:
        "BookMundi is a sophisticated travel booking platform through which travelers and locals alike can book experiences and things to do around the world, crafted by qualified tour companies. Best of all, the user can chat in real-time to the tour operator through Bookmundi’s latest chat feature.",
      // Links to the project
      links: [
        // {
        //     // Tooltip of the link
        //     tooltip: 'See source',
        //     // Link URL
        //     link: 'https://github.com/xwiki-contrib/application-interactive-maps',
        //     // Icon of the button
        //     icon: Icons['code']
        // },
        {
          tooltip: "Visit Website",
          link: "https://www.bookmundi.com/",
          icon: Icons["link"],
        },
      ],
    },
    {
      name: "Smoothbalance - a financial management app",
      icon: <AccountBalanceWalletIcon />,
      description:
        "Smoothbalance is a financial management app for sole traders, limited companies, and their accountant. The app allows users to create and send quotations and invoices and log expenses. Smoothbalance is designed for plumbers, interior decorators, graphic designers, tree surgeons, painters and decorators, tutors, musicians, web developers, programmers, translators, electricians, network engineers, PC technician and anyone who are either self-employed or running a small business. This app is to make sure businesses are never late to send quotations, invoices or record expenses.",
      links: [
        {
          tooltip: "Visit Website",
          link: "https://smoothbalance.com/",
          icon: Icons["link"],
        },
      ],
    },
  ],
};

/** Config for sections */
const CustomSectionsConfig = [
  // {
  //   name: "career",
  //   headerIcon: <RateReviewIcon />,
  //   content: (
  //     <>
  //       <h2>{Icons["work"]} Work Experience</h2>

  //       <div className="work-experience">
  //         <div className="current-position">
  //           <h3 style={{ fontSize: "1.4rem" }}>Current Position</h3>
  //           <p>
  //             Data Warehouse Developer | Systems Project Analyst
  //             <br />
  //             Clackamas County (Location: Oregon City, Oregon)
  //           </p>
  //         </div>

  //         <div className="previous-positions">
  //           <h3 style={{ fontSize: "1.4rem" }}>Previous Positions</h3>
  //           <ul>
  //             <li>
  //               Senior Software Engineer
  //               <br />
  //               Bookmundi ApS. (Head Office: Skovlunden, Denmark)
  //             </li>
  //             <li>
  //               Software Engineer | Data Engineer
  //               <br />
  //               Cotiviti Inc. (Head Office: South Jordan, Utah)
  //             </li>
  //             <li>
  //               Software Engineer | Database Engineer | Team Lead
  //               <br />
  //               Spiralogics, Inc. (Head Office: Georgia, Atlanta)
  //             </li>
  //             <li>
  //               Android Application Developer
  //               <br />
  //               WebBank Nepal (Kathmandu, Nepal)
  //             </li>
  //           </ul>
  //         </div>
  //       </div>
  //     </>
  //   ),
  // },
  {
    name: "Education",
    headerIcon: <BookIcon />,
    extraClass: "about-section section-reverse",
    content: (
      <div className="education-skills-container">
        <div className="education-column">
          <h3 style={{ fontSize: "1.4rem" }}>Maharishi International University</h3>
          <p>Master of Science - MS, Computer Science</p>
          <h3 style={{ fontSize: "1.4rem" }}>Advanced College of Engineering and Management</h3>
          <p>Bachelors in Computer Engineering</p>
        </div>
      </div>
    ),
  },
  {
    name: "Skills",
    headerIcon: <SkillIcon />,
    extraClass: "about-section section-reverse",
    content: (
      <>
        <div className="about-section">
          <div className="education-skills-container">
            <div className="x-b-container skills-column">
              <div className="skill-card">
                <h4>Java</h4>
              </div>
            </div>
            <div className="x-b-container skills-column">
              <div className="skill-card">
                <h4>Python</h4>
              </div>
            </div>
            <div className="x-b-container skills-column">
              <div className="skill-card">
                <h4>PL/SQL</h4>
              </div>
            </div>
            <div className="x-b-container skills-column">
              <div className="skill-card">
                <h4>JavaScript</h4>
              </div>
            </div>
            <div className="x-b-container skills-column">
              <div className="skill-card">
                <h4>Power BI</h4>
              </div>
            </div>
            <div className="x-b-container skills-column">
              <div className="skill-card">
                <h4>AWS</h4>
              </div>
            </div>
            <div className="x-b-container skills-column">
              <div className="skill-card">
                <h4>Kafka</h4>
              </div>
            </div>
          </div>
        </div>
        
      </>
    ),
  },

  // PROJECTS SECTION
  {
    // Name of the section
    name: "projects",
    // Icon next to the header title. The headerIcon can use any SVG icon, material icons are preferred
    // See https://material-ui.com/components/material-icons/
    headerIcon: <ComputerIcon />,
    // Extra classes to apply styling
    // "section-reverse" class makes a section right to left
    extraClass: "",
    // If you don't want a section in menu, make this true
    notInMenu: false,
    // Content inside the section
    content: (
      <>
        {ProjectsConfig.projects.map((project, index) => {
          return (
            <div key={"project-" + index}>
              <h3 style={{ fontSize: "1.4rem" }}>
                {project.icon} {project.name}
              </h3>
              <p>{project.description}</p>
              <div style={{ textAlign: "right" }}>
                {project.links.map((link, linkIndex) => {
                  return (
                    <CircleButton
                      key={"project-link-" + index + linkIndex}
                      link={link.link}
                      target="_blank"
                      tooltip={link.tooltip}
                      size={1.4}
                    >
                      {link.icon} 
                    </CircleButton>
                  );
                })}
              </div>
            </div>
          );
        })}
      </>
    ),
  },
  // ABOUT SECTION
  {
    name: "about",
    headerIcon: <InfoIcon />,
    extraClass: "about-section section-reverse",
    content: (
      <>
        <h4>{Icons["helpoutline"]} who is this guy?</h4>
        <p>
          Experienced and multi-skilled Software Developer with 8 years of
          experience playing a key role in the designing, development, and
          maintenance of enterprise software. Hands-on expertise in Data
          engineering platforms and programming; back-end development and
          front-end development. Experience in building data warehousing, ETL,
          batch and stream processing, and writing complex queries. Skilled in
          using technologies such as Microsoft SQL Server, Oracle, SSIS, Data
          Visualization (MicroStrategy, PowerBI), ETL Process, T-SQL, PL/SQL,
          Java, Spring Boot, Kafka, and RESTful API. Experience in industries
          like the US healthcare and tourism industry in Denmark. Effective in
          analytical and problem-solving skills and help the organization move
          towards its strategic goal.
          <br />
          <br />I define myself as a continuous learner and prefer to keep
          learning and challenge myself. Let's connect and explore how we can
          collaborate :)
        </p>
        <h4>{Icons["code"]} Honors-Awards</h4>
        <ul>
          <li>
            Angular Attack 2018 - 48 hr global hackathon| Category: Public
            Favorite | Project: Multilayout Generator
          </li>
          <li>
            React Riot | Category: Hackers Favorite | Project: A good day to
            quiz hard
          </li>
        </ul>
      </>
    ),
  },
  {
    name: "contact",
    extraClass: "section-hide",
    headerIcon: <AddIcCallIcon />,
    content: (
     <></>
    ),
  },
  // {
  //   name: "reviews",
  //   headerIcon: <RateReviewIcon />,
  //   content: (
  //     <>
  //       <h4 style={{ fontSize: "2em" }}>
  //         {Icons["helpoutline"]} what do others say?
  //       </h4>
  //       <p>
  //         <span style={{ fontSize: "5rem", height: "30px", display: "block" }}>
  //           “
  //         </span>
  //         <br />
  //         Kushal did an excellent job for us on Phoenix. He worked hard, writing
  //         very good and legible code and made an invaluable contribution to the
  //         project.
  //       </p>
  //       <p>
  //         We like that he is very motivated, self-driven and is already an
  //         experienced and knowledgable coder.
  //       </p>
  //       <p style={{ textAlign: "right", fontSize: "small" }}>
  //         <i>
  //           <b>Edward Moyse</b>
  //           <br />
  //           Software Coordinator
  //           <br />
  //           CERN
  //         </i>
  //       </p>
  //       <p>
  //         <span style={{ fontSize: "5rem", height: "30px", display: "block" }}>
  //           “
  //         </span>
  //         <br />
  //         The mentors have noticed that Kushal has strong technical skills, an
  //         ability to learn quickly new technologies, that he is very flexible
  //         and open minded, ready to discuss his views and to ponder about the
  //         pros and cons in a thoughtful and collected manner, making his own
  //         decisions taking into account the feedback he gets without accepting
  //         everything nor rejecting anything.
  //       </p>
  //       <p style={{ textAlign: "right", fontSize: "small" }}>
  //         <i>
  //           <b>Stéphane Lauriere &amp; Ecaterina Moraru</b>
  //           <br />
  //           XWiki SAS
  //         </i>
  //       </p>
  //       <p>
  //         <span style={{ fontSize: "5rem", height: "30px", display: "block" }}>
  //           “
  //         </span>
  //         <br />I have really appreciated your confidence to solve problems
  //         yourself and follow your own intuition even when it goes against what
  //         I was suggesting. I feel you were mostly right :)
  //       </p>
  //       <p style={{ textAlign: "right", fontSize: "small" }}>
  //         <i>
  //           <b>James Knight</b>
  //           <br />
  //           GeNN Team
  //           <br />
  //           Research Fellow
  //           <br />
  //           The University of Sussex
  //         </i>
  //       </p>
  //     </>
  //   ),
  // },
  // {
  //   name: "contact",
  //   headerIcon: <RateReviewIcon />,
  //   content: (
  //     <>
  //       <h4>{Icons["contact"]} Contact Me</h4>
  //       <form>
  //         <div className="form-group">
  //           <label htmlFor="name">Name:</label>
  //           <input type="text" id="name" name="name" required />
  //         </div>
  //         <div className="form-group">
  //           <label htmlFor="email">Email:</label>
  //           <input type="email" id="email" name="email" required />
  //         </div>
  //         <div className="form-group">
  //           <label htmlFor="message">Message:</label>
  //           <textarea id="message" name="message" rows="4" required></textarea>
  //         </div>
  //         <button type="submit">Send</button>
  //       </form>
  //     </>
  //   ),
  // }
];

export { CommonConfig, ProjectsConfig, CustomSectionsConfig, Icons };
