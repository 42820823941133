// src/components/CareerSection/CareerSection.jsx
import React from 'react';
import './CareerSection.scss';

const CareerSection = () => {
  const careerData = [
    {
      position: 'Data Warehouse Developer | Systems Project Analyst, Senior',
      startTime: '2023',
      endTime: 'Present',
      location: 'Clackamas County (Oregon City, Oregon)',
      roles: [
        'Responsible for architectural design and implementation of the enterprise data warehouse to support business intelligence initiatives (Housing and Urban Development - HUD programs) and reporting needs.',
        'Design, develop, and optimize data pipelines to ensure efficient extraction, transformation, and loading processes.',
        'Created and maintained interactive Power BI reports and dashboards to provide actionable insights and support decision-making processes.'
      ]
    },
    {
      position: 'Senior Software Engineer',
      startTime: '2021',
      endTime: '2022',
      location: 'Bookmundi ApS. (Skovlunden, Denmark)',
      roles: [
        'Restructured advanced data analytics capabilities into the application supporting 180K monthly users, including an ETL pipeline for tracking tour operator’s response rates and user touch points.',
        'Innovated a Destination Tagger service in Python using Python SpaCy Library to effectively extract destination information from text data, which was later incorporated in every Product (Tour) Page in the Bookmundi application.',
      ]
    },
    {
      position: 'Software Engineer | Data Engineer',
      startTime: '2019',
      endTime: '2021',
      location: 'Cotiviti Inc. (South Jordan, Utah)',
      roles: [
        'Led process optimization by converting manual team processes into semi or fully automated applications',
        'Migrated existing PL/SQL codes and perform mapping, error handling, creating components, automation using Oracle Data Integrator (ODI).',
      ]
    },
    {
      position: 'Software Engineer | Database Engineer | Team Lead',
      startTime: '2015',
      endTime: '2019',
      location: 'Spiralogics, Inc. (Georgia, Atlanta)',
      roles: [
        'Worked as a team lead & communicated frequently with cross-functional teams to understand the business and functional requirements to provide solutions.',
        'Ensure accurate and timely reporting of 1.5K insurance coverage information during every submission on behalf of Responsible Reporting Entities (RREs) to the Centers for Medicare and Medicaid Services (CMS).',
        'Developed an application for Responsible Reporting Entities (RREs) to report information related to settlements, judgments, or other payments to comply with MMSEA Section 111, ensuring compliance within the US healthcare system, automating file handling for various Responsible Reporting Entities (RREs) and submitting data to CMS.'
      ]
    },
    {
      position: 'Android Application Developer',
      startTime: '2015',
      endTime: '2015',
      location: 'WebBank Nepal (Kathmandu, Nepal)',
      roles: [
        'Contributed to the development of mobile applications on the Android platform.'
      ]
    }
  ];

  return (
    <div className="career-section" id="career-section">
      <h2>Professional Experience</h2>
      <div className="timeline"></div>
      <div className="jobs-container">
        {careerData.map((job, index) => (
          <div key={index} className="job">
            <h3>{job.position}</h3>
            <p className="time-location">
              {job.startTime} - {job.endTime} | {job.location}
            </p>
            <ul>
              {job.roles.map((role, roleIndex) => (
                <li key={roleIndex}>{role}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CareerSection;
