// src/components/ContactForm/ContactForm.jsx
import React, { useState } from "react";
import "./ContactForm.scss";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    message: "",
    email: "",
    name: "",
    phone: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.title = formData.title
      ? ""
      : "Message title is required";
    tempErrors.message = formData.message ? "" : "Message is required";
    tempErrors.email = formData.email ? "" : "Email is required";
    tempErrors.name = formData.name ? "" : "Name is required";
    // Phone is optional, so no validation needed for it

    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setLoading(true);
      setSuccess(false);

      
      try {
        const optionsResponse = await fetch(
          "https://cnb5wucauc.execute-api.us-east-1.amazonaws.com/v1/contact-kushal",
          {
            method: "OPTIONS",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const response = await fetch(
          "https://cnb5wucauc.execute-api.us-east-1.amazonaws.com/v1/contact-kushal",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        if (response.ok) {
          setSuccess(true);
          setLoading(false);
          setFormData({
            title: "",
            message: "",
            email: "",
            name: "",
            phone: "",
          });
        } else {
          throw new Error("Failed to send message");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false)
      }
    }
  };

  return (
    <div className="contact-form-wrapper section-contact">
      <h2>Contact Me</h2>
      <form className="contact-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="title">Message Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            className={errors.title ? "error" : ""}
          />
          {errors.title && (
            <span className="error-text">{errors.title}</span>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="message">Message*</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className={errors.message ? "error" : ""}
          />
          {errors.message && (
            <span className="error-text">{errors.message}</span>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="email">Email*</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={errors.email ? "error" : ""}
          />
          {errors.email && <span className="error-text">{errors.email}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="name">Name*</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={errors.name ? "error" : ""}
          />
          {errors.name && (
            <span className="error-text">{errors.name}</span>
          )}
        </div>
        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
          />
        </div>
        <button type="submit" className="submit-button" disabled={loading}>
          {loading ? "Submitting..." : "Submit"}
        </button>
        {success && (
          <div className="success-message">
            Thank you! Your message has been sent successfully.
          </div>
        )}
      </form>
    </div>
  );
};

export default ContactForm;
