import React from 'react';
import './BlogSection.scss';

const BlogSection = () => {
  const blogData = [
    {
      title: 'Understanding HDFS: A Deep Dive into Hadoop Distributed File System',
      link: 'https://medium.com/@kushalshr/understanding-hdfs-a-deep-dive-into-hadoop-distributed-file-system-4e9abb8040d3',
      excerpt: 'The world of big data is vast and complex, requiring robust and efficient systems to manage and process large datasets. One of the key technologies in this domain is the Hadoop Distributed File System (HDFS), a critical component of the Hadoop ecosystem. In this blog, we will explore the architecture, storage capabilities, performance features, data integrity mechanisms, and data access methods of HDFS. ',
      image: 'https://miro.medium.com/v2/resize:fit:1064/format:webp/1*0oVFi4345wJAUSejiZCYxw.png'
    },
    {
      title: 'Mastering Fast Arriving Dimensions (FAD): Lessons from My Experience',
      link: 'https://medium.com/@kushalshr/mastering-fast-arriving-dimensions-fad-lessons-from-my-experience-c94375570f7e',
      excerpt: 'Managing FADs was crucial for us due to the dynamic nature of our data, especially in areas like customer activities and tour product attributes. In this blog, I’ll share insights from my experience, shedding light on what FADs are, their characteristics, how we historically tracked and stored them, and how we designed our ETL processes to handle them efficiently.',
      image: 'https://miro.medium.com/v2/resize:fit:1240/format:webp/1*3b5XOtQ42rk0ZRupELmoJA.png'
    },
  ];

  return (
    <div className="blog-section" id="blog-section">
      <h2>Blog</h2>
      <div className="blogs-container">
        {blogData.map((blog, index) => (
          <div key={index} className="blog">
            <a href={blog.link} target="_blank" rel="noopener noreferrer">
              <img src={blog.image} alt={blog.title} />
              <div className="blog-info">
                <h3>{blog.title}</h3>
                <p>{blog.excerpt}</p>
              </div>
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BlogSection;
